import React from 'react';
import Form from "./Form";
import Introtext from "./Introtext";

function Body() {
    return(
        <section className="page-content">
            <Introtext/>
            <Form />
        </section>
    );
}

export default Body;