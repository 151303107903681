import React from 'react';

const LanguageContext = React.createContext({
    languageCode: 'ru',
    switchLanguage: () => {}
});
const LanguageConsumer = LanguageContext.Consumer;

class LanguageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.switchLanguage = (e) => {
            this.setState({
                languageCode: e.target.value
            });
        };
        this.state = {
            languageCode: 'ru',
            switchLanguage: this.switchLanguage
        };
    }

    render() {
        return (
            <LanguageContext.Provider
                value={this.state}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}

export {LanguageContext};
export {LanguageConsumer};
export {LanguageProvider};