import React from 'react';
import logo from '../assets/logo.svg';
import {LanguageConsumer} from "./LanguageProvider";

function Header() {

    return (
        <header>
            <LanguageConsumer>
                {(language) => (
                    <div className="language-switcher-wrap">
                        <select onChange={language.switchLanguage}>
                            <option value='ru'>Ru</option>
                            <option value='en'>EN</option>
                        </select>
                    </div>
                )}
            </LanguageConsumer>
            <img src={logo} alt="Lyka pharmaceuticals"/>
        </header>
    );
}

export default Header;