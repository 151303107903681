import React from 'react';
import {LanguageConsumer} from "./LanguageProvider";
import Dictionary from "./Dictionary";

function Footer() {

    return(
        <LanguageConsumer>
            {(language) => (
                <footer>
                    {Dictionary[language.languageCode]['footerText']} - <a href="http://lykapharma.com" target="_blank" rel="nofollow noopener noreferrer">lykapharma.com</a>
                </footer>
            )}
        </LanguageConsumer>
    );
}

export default Footer;