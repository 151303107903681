import React from 'react';
import HTMLReactParser from "html-react-parser";
import spinner from "../assets/spinner.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {LanguageContext} from "./LanguageProvider";
import Dictionary from "./Dictionary";

class Form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            prodNumber: '',
            message: '',
            checked: false
        };
        this.recaptchaRef = React.createRef();
        this.executeRecaptcha = this.executeRecaptcha.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitData = this.submitData.bind(this);
    }

    executeRecaptcha(event) {

        event.preventDefault();
        if (this.state.checked || this.state.loading) return 0;
        this.recaptchaRef.current.execute();
    }

    handleChange(event) {

        this.setState({
            prodNumber: event.target.value,
            checked: false
        });
    }

    submitData() {

        function status(response) {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(new Error(response.statusText))
            }
        }

        function text(response) {
            return response.text()
        }

        if (this.state.checked || this.state.loading) return 0;

        this.setState({
            loading: true
        });
        fetch('/core/validateNumber.php', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: `siteLanguage=${this.context.languageCode}&productNumber=${this.state.prodNumber}&g-recaptcha=${this.recaptchaRef.current.getValue()}`
        })
            .then(status)
            .then(text)
            .then(data => this.setState({
                message: data,
                checked: true,
                loading: false
            }))
            .catch((err) => this.setState({
                message: Dictionary[this.context.languageCode['errorText']] + err,
                loading: false
            }));
        this.recaptchaRef.current.reset();
    }

    render() {
        this.language = this.context;

        return (
            <div className="form-container">
                <form onSubmit={this.executeRecaptcha}>
                    <div className="input-wrap">
                        <span>
                            <input type="text" name="productNumber" className="number-input" required={true}
                                   placeholder={Dictionary[this.language.languageCode]['codePlaceholder']} value={this.state.prodNumber}
                                   onChange={this.handleChange}/>
                            {this.state.loading ? <img src={spinner} className="spinner" alt={Dictionary[this.language.languageCode]['loaderAlt']}/> : <></>}
                        </span>
                    </div>
                    <div className="input-wrap">
                        <input type="submit" className="submit-button" value={Dictionary[this.language.languageCode]['checkButtonText']}/>
                    </div>
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        size="invisible"
                        sitekey="6Le0QKwUAAAAAB-TdUoEDtbjCmYLanzDX7UKSYF4"
                        badge="bottomleft"
                        onChange={this.submitData}/>
                </form>
                <div className="message-container">
                    <p>{HTMLReactParser(this.state.message)}</p>
                </div>
            </div>
        );
    }
}

Form.contextType = LanguageContext;

export default Form;