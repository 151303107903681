const Dictionary={
    ru : {
        introText: 'Чтобы проверить, является ли купленная Вами продукция фирмы Lyka pharmaceuticals оригинальной, введите защитный код в поле ниже.',
        codePlaceholder: 'Введите код',
        loaderAlt: 'Загрузка',
        checkButtonText: 'Проверить',
        errorText: 'Во время запроса произошла ошибка. ',
        footerText: 'Наш официальный сайт'
    },
    en : {
        introText: 'To check whether the Lyka pharmaceuticals products you purchased are original, enter the security code in the box below.',
        codePlaceholder: 'Enter code',
        loaderAlt: 'Loading',
        checkButtonText: 'Check',
        errorText: 'An error occurred during the request. ',
        footerText: 'Our official site'
    }
};

export default Dictionary;