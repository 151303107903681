import React from 'react';
import {LanguageConsumer} from "./LanguageProvider";
import Dictionary from "./Dictionary";

function Introtext(){
    return(
        <LanguageConsumer>
            {(language)=>(
                <div className="intro-text">
                    <p>
                        {Dictionary[language.languageCode]['introText']}
                    </p>
                </div>
            )}
        </LanguageConsumer>
    );
}

export default Introtext;