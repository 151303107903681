import React from 'react';
import Header from './components/Header';
import Body from "./components/Body";
import Footer from './components/Footer';
import {LanguageProvider} from "./components/LanguageProvider";
import './assets/styles/App.scss';

function App() {

    return (
        <LanguageProvider>
            <div className="page-container">
                <div className="wrapper">
                    <Header/>
                    <Body/>
                </div>
                <Footer/>
            </div>
        </LanguageProvider>
    );
}

export default App;
